import React from 'react'
import { Link } from 'gatsby'
import Section from '../../../common/SectionComponent/Section'
import * as style from './post-navigation.module.scss'
import MobileShareWidget from '../ShareWidget/MobileShareWidget'
import * as tagStyles from '../../../common/BlogTags/blog-tags.module.scss'
import { setName } from '../../Blog/BlogPlates/functions'
import keyGenerator from '../../../../utils/keyGenerator'

const PostNavigationSection = ({ tags, location, title }) => (
  <Section newClass={style.content} isLight>
    <div className={style.nav}>
      <div className={tagStyles.tags}>
        {tags.map((e, i) => (
          <div key={keyGenerator(i, e.name)}>
            <Link to={`/blog/?tag=${setName(e.name)}`}>
              <div className={tagStyles.tag}>
                <span>{e.name}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <MobileShareWidget path={location.href} title={title} />
    </div>
  </Section>
)

export default PostNavigationSection
