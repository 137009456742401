import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import parse from 'html-react-parser'
import Section from '../../../common/SectionComponent/Section'
import * as style from './post-faq.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import useWpContentOverflow from '../../../../hooks/useWpContentOverflow'
import LeftBorderTextComponent from '../../../reusable/LeftBorderTextComponent/LeftBorderTextComponent'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import { parseContentLink } from '../../../../utils/functions'

const OverflowLeftBorderTextComponent = ({ faqDescription, ...props }) => {
  const { toggle, isOverflow } = useWpContentOverflow('faq')
  const faqDescriptionHTML = parseContentLink(faqDescription)

  const button = {
    text: isOverflow ? 'Show less' : 'Show More',
    arrowDirection: isOverflow ? 'up' : 'down',
    isLight: true,
    onClick: toggle,
  }

  return (
    <LeftBorderTextComponent
      isPrimary
      size="small"
      descriptionClass={style.contentItemDescription}
      description={isOverflow ? faqDescriptionHTML : faqDescriptionHTML[0]}
      button={faqDescriptionHTML.length > 2 && button}
      {...props}
    />
  )
}

const PostFaq = ({ faqData }) => {
  const [activeItem, setActiveItem] = useState(0)
  const activeItemToggle = (n) => () => {
    setActiveItem(n)
  }

  return (
    <Section newClass={style.section} isLight title={faqData.faqTitle}>
      <HiddenScrollItem id="faq" />
      <div className={style.faqItem}>
        {faqData.faqItem?.map((item, index) => {
          const { description, title } = item
          return (
            <Fade key={keyGenerator(index, title)} bottom>
              <OverflowLeftBorderTextComponent
                index={index}
                title={title}
                faqDescription={description}
                activeItem={activeItem}
                onClick={activeItemToggle}
              />
            </Fade>
          )
        })}
      </div>
    </Section>
  )
}

export default PostFaq
