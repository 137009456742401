import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../layouts/MainLayout'
import PostMainSection from '../components/Pages/OnePost/PostMainSection/PostMainSection'
import PostContent from '../components/Pages/OnePost/PostContent/PostContent'
import PostContextProvider from '../utils/PostContextProvider'
import ShareWidget from '../components/Pages/OnePost/ShareWidget/ShareWidget'
import WidgetTrigger from '../components/Pages/OnePost/ShareWidget/WidgetTrigger'
import OneServiceBlog from '../components/Pages/OneService/OneServiceBlog/OneServiceBlog'
import Subscribe from '../components/Pages/OnePost/Subscribe/Subscribe'
import PostFaq from '../components/Pages/OnePost/PostFaq/PostFaq'
import PostNavigationSection from '../components/Pages/OnePost/PostNavigationSection/PostNavigationSection'
import PostPagesNavigation from '../components/Pages/Blog/PostPagesNavigation/PostPagesNavigation'

const OnePost = ({
  pageContext: {
    navigationData,
    slug: {
      blogPost: {
        postFaq,
        blogPosts,
        blogHeader,
        body,
        author,
        title,
        minutesToRead,
        categories,
        image: {
          localFile: { url: bgImage },
        },

        fileFields,
      },
      metaTags,
      tags: { nodes: tags },
    },
  },
  location,
}) => {
  const [contentSize, setContentSize] = useState(0)
  const [mainBlockSize, setMainBlockSize] = useState(0)
  const content = useRef()
  const mainBlock = useRef()
  const { seoHiddenTitle } = metaTags

  useEffect(() => {
    const size = Math.round(content.current.clientHeight)
    const mainSize = Math.round(mainBlock.current.clientHeight)
    setContentSize(size)
    setMainBlockSize(mainSize)
  }, [])

  return (
    <PostContextProvider>
      <MainLayout seoData={metaTags} location={location}>
        <div id="top" />
        <WidgetTrigger
          key={contentSize}
          contentSize={contentSize}
          mainBlockSize={mainBlockSize}
        />
        <ShareWidget path={location.href} title={title} />
        <div ref={mainBlock}>
          <PostMainSection
            hiddenTitle={seoHiddenTitle}
            title={title}
            background={bgImage}
            tags={categories}
            author={author}
            minutesToRead={minutesToRead}
          />
        </div>

        <div ref={content}>
          <PostContent
            data={body}
            fileFields={fileFields}
            url={location.href}
          />
        </div>
        <PostFaq faqData={postFaq} />
        <PostNavigationSection tags={tags} location={location} title={title} />
        <PostPagesNavigation
          navigationData={navigationData}
          location={location}
        />
        <Subscribe />
        <OneServiceBlog isGray isLight data={blogPosts} heading={blogHeader} />
      </MainLayout>
    </PostContextProvider>
  )
}

export default OnePost
