import React from 'react'
import classnames from 'classnames'
import Section from '../../../common/SectionComponent/Section'
import BlogPlate from '../../../reusable/BlogPlate/BlogPlate'
import * as style from './one-service-blog.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'

const OneServiceBlog = ({
  data,
  heading: { title, description },
  isLight,
  isGray,
}) => (
  <Section
    newClass={isGray && style.grayBg}
    isLight={isLight}
    title={title || 'Read more'}
  >
    <p
      className={classnames(
        isLight ? 'text-color__gray--on-light' : 'text-color__gray--on-dark'
      )}
    >
      {description}
    </p>
    <div className={style.wrap}>
      {data &&
        data.map((e, i) => (
          <div key={keyGenerator(i, e.title)}>
            <BlogPlate data={e} />
          </div>
        ))}
    </div>
  </Section>
)

export default OneServiceBlog
