import React, { useContext, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { PostContext } from '../../../../utils/context'
import * as style from './share-widget.module.scss'

const WidgetTrigger = ({ contentSize, mainBlockSize }) => {
  const { setShareWidget } = useContext(PostContext)
  const [widgetState, setWidgetState] = useState(false)

  const ref = useRef()

  useEffect(() => {
    setShareWidget(widgetState)
  }, [widgetState])

  const handleScroll = () => {
    const position = Math.round(ref.current.getBoundingClientRect().top) * -1
    const end = !(position > contentSize + window.innerHeight)
    const start = position > mainBlockSize

    const show = start && end

    if (show && !widgetState) {
      setWidgetState(true)
    } else if (!show && widgetState) {
      setWidgetState(false)
    }
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [widgetState])

  return (
    <div id="widgetTrigger" ref={ref} className={cn(style.widgetTriggers)} />
  )
}

export default WidgetTrigger
