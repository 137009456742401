import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import className from 'classnames'
import * as style from '../../../common/PageNavigator/page-navigator.module.scss'

const PostPagesNavigation = ({ navigationData, location }) => {
  const [prevItem, setPrevItem] = useState(null)
  const [nextItem, setNextItem] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const currentPathName = location.pathname.match(/([^\/]*)\/*$/)[1]

  const setCurrentItem = () => {
    navigationData.forEach((e, i) => {
      if (e.slug === currentPathName) {
        setCurrentIndex(i)
      }
    })
  }

  useEffect(() => {
    if (navigationData.length) {
      setCurrentItem()
    }
  }, [currentPathName])

  useEffect(() => {
    if (currentIndex !== null) {
      const prevIndex = currentIndex === 0 ? null : currentIndex - 1
      const nextIndex =
        currentIndex === navigationData.length - 1 ? null : currentIndex + 1
      setPrevItem(navigationData[prevIndex])
      setNextItem(navigationData[nextIndex])
    }
  }, [currentIndex])

  return (
    <section className={style.section}>
      <div className="container">
        <div className={style.linksWrap}>
          <div>
            {prevItem && (
              <Link to={`/${prevItem.url}/${prevItem.slug}/`}>
                <div className={className(style.plate, style.platePrev)}>
                  <p className={style.linkText}>Previous Article</p>
                  <p className={style.linkHeading}>{prevItem.title}</p>
                </div>
              </Link>
            )}
          </div>
          <div>
            {nextItem && (
              <Link to={`/${nextItem.url}/${nextItem.slug}/`}>
                <div className={className(style.plate, style.plateNext)}>
                  <p className={style.linkText}>Next Article</p>
                  <p className={style.linkHeading}>{nextItem.title}</p>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PostPagesNavigation
