// extracted by mini-css-extract-plugin
export var animate = "share-widget-module--animate--xQXet";
export var animateCircle = "share-widget-module--animate-circle--d+SsD";
export var bounce = "share-widget-module--bounce--ctrdc";
export var end = "share-widget-module--end--Tc3oR";
export var isMobile = "share-widget-module--isMobile--Gp2K4";
export var ldsRing = "share-widget-module--lds-ring--6bUYN";
export var likeButton = "share-widget-module--likeButton--14kAN";
export var likes = "share-widget-module--likes--WlhGT";
export var likesTitle = "share-widget-module--likesTitle--7GgOy";
export var shareTitle = "share-widget-module--shareTitle--7YOw5";
export var social = "share-widget-module--social--+Ia3L";
export var socials = "share-widget-module--socials--YMDgY";
export var widget = "share-widget-module--widget--yJimG";
export var widgetTriggers = "share-widget-module--widgetTriggers--wThv+";