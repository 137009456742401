import React, { useContext, useEffect, useState } from 'react'

import cn from 'classnames'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import * as style from './share-widget.module.scss'
import twitter from '../../../../../static/icons/socials/twitter-black.svg'
import facebook from '../../../../../static/icons/socials/facebook-black.svg'
import linkedin from '../../../../../static/icons/socials/linked-black.svg'
import likesIcon from '../../../../../static/icons/likes.svg'
import { PostContext } from '../../../../utils/context'

function kFormatter(num) {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num)
}

const ShareWidget = ({ path, title }) => {
  const isWindowAvailable = typeof window !== 'undefined'

  let localStorageLikes = {
    likes: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
    liked: false,
  }

  if (isWindowAvailable) {
    const localData = window.localStorage.getItem(`likes-${path}`)
    localStorageLikes = localData ? JSON.parse(localData) : localStorageLikes
  }

  const { shareWidget } = useContext(PostContext)
  const [likes, setLikes] = useState(localStorageLikes.likes)

  const triggerLikes = () => {
    const newLikes = Number(likes) + 1
    if (isWindowAvailable) {
      const isLiked = localStorageLikes.liked
      if (!isLiked) {
        setLikes(newLikes)
        window.localStorage.setItem(
          `likes-${path}`,
          JSON.stringify({
            likes: newLikes,
            liked: true,
          })
        )
      }
    }
  }

  useEffect(() => {
    if (isWindowAvailable) {
      window.localStorage.setItem(
        `likes-${path}`,
        JSON.stringify(localStorageLikes)
      )
    }
  }, [])

  return (
    <div className={cn(style.widget, shareWidget && style.animate)}>
      <div className={style.likes}>
        <div className={style.likesTitle}>{kFormatter(likes)}</div>
        <button
          onClick={triggerLikes}
          type="button"
          className={style.likeButton}
        >
          <img width={30} height={30} src={likesIcon} alt="alt" />
        </button>
      </div>
      <div>
        <div className={style.shareTitle}>Share</div>
        <ul className={style.socials}>
          <TwitterShareButton url={path} title={title}>
            <li className={style.social}>
              <img width={24} height={24} src={twitter} alt="twitter" />
            </li>
          </TwitterShareButton>
          <LinkedinShareButton url={path}>
            <li className={style.social}>
              <img width={24} height={24} src={linkedin} alt="linkedin" />
            </li>
          </LinkedinShareButton>
          <FacebookShareButton url={path}>
            <li className={style.social}>
              <img width={24} height={24} src={facebook} alt="facebook" />
            </li>
          </FacebookShareButton>
        </ul>
      </div>
    </div>
  )
}

export default ShareWidget
