import React from 'react'
import Fade from 'react-reveal/Fade'
import className from 'classnames'
import { Link } from 'gatsby'
import * as style from './post-main-section.module.scss'
import PostAuthors from './PostAuthors'
import * as tagStyles from '../../../common/BlogTags/blog-tags.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import { setName } from '../../Blog/BlogPlates/functions'

const PostMainSection = ({
  hiddenTitle,
  background,
  title,
  description,
  tags,
  author,
  minutesToRead,
}) => (
  <section className={className(style.main)}>
    <div
      style={{ backgroundImage: `url('${background}')` }}
      className={style.mainBg}
    >
      <div className={className('container', style.container)}>
        <div className={className(style.headings)}>
          <Fade left>
            {hiddenTitle ? (
              <>
                <h1 className={style.titleHidden}>{hiddenTitle}</h1>
                <p className={className(style.title, 'text-color--primary')}>
                  {title}
                </p>
              </>
            ) : (
              <h1 className={className(style.title, 'text-color--primary')}>
                {title}
              </h1>
            )}
          </Fade>
          <Fade bottom>
            <p className={className('text-color--primary', style.description)}>
              {description}
            </p>
          </Fade>
        </div>
        <Fade left>
          <div className={style.info}>
            <div className={tagStyles.tags}>
              {tags.map((e, i) => {
                const url = setName(e.name)
                return (
                  <div key={keyGenerator(i, e.name)}>
                    <Link to={`/blog/?category=${url}`}>
                      <div className={tagStyles.tag}>
                        <span>{e.name}</span>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
            {minutesToRead && (
              <div className={style.read}>{minutesToRead} min read</div>
            )}
          </div>
        </Fade>
      </div>
    </div>
    <PostAuthors authors={author} />
  </section>
)
export default PostMainSection
